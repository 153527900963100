import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from "../../../../services/common/common.service";
import { LoadingState } from "../../../../state/loading-state.enum";
import { catchError, Subject, takeUntil, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { PowerBIReportInformationDTO } from "../../../../models/reports/reports";
import { Router } from "@angular/router";

@Component({
  selector: 'app-reports-sidebar',
  templateUrl: './reports-sidebar.component.html',
  styleUrls: ['./reports-sidebar.component.css']
})
export class ReportsSidebarComponent implements OnInit, OnDestroy {
  componentDestroyed$ = new Subject<void>();
  state = LoadingState.loading;
  readonly LoadingState = LoadingState;

  // All available reports
  allReports: PowerBIReportInformationDTO[] = [];
  // Sorted report types
  sortedReportTypes: { reportTypeName: string, reportTypeId: number }[] = [];
  // Report options for the selected type
  reportOptions: PowerBIReportInformationDTO[] = [];
  staticReportOptions: { reportName: string, routerLink: string }[] = [];

  // Currently selected report type and option
  selectedReportTypeId: number | null = null;
  selectedReportType: string | null = null;
  selectedReportOption: PowerBIReportInformationDTO | null = null;

  constructor(
    private commonService: CommonService,
    private toastrService: ToastrService,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    this.commonService.getPowerBIReportInformation().pipe(
      takeUntil(this.componentDestroyed$),
      catchError(error => {
        this.toastrService.error('Failed to load reports');
        return throwError(() => error);
      })
    ).subscribe(reports => {
      this.allReports = reports;

      // Group and sort report types by reportTypeId
      const reportTypeMap = new Map<number, string>();
      reports.forEach(report => {
        if (!reportTypeMap.has(report.reportTypeId)) {
          reportTypeMap.set(report.reportTypeId, report.reportTypeName);
        }
      });

      // Adding the "Invoices" report type manually
      reportTypeMap.set(999, "Invoices");

      // Sort report types by reportTypeId
      this.sortedReportTypes = Array.from(reportTypeMap.entries())
        .sort((a, b) => a[0] - b[0])
        .map(([reportTypeId, reportTypeName]) => ({
          reportTypeId,
          reportTypeName,
        }));

      this.state = LoadingState.loaded;
    });
  }

  // When a user selects a report type
  onReportTypeSelect(reportTypeId: number): void {
    this.selectedReportTypeId = reportTypeId;
    if (reportTypeId === 999) {
      // Static "Invoices" type
      this.selectedReportType = "Invoices";
      this.staticReportOptions = [
        { reportName: 'Client Draft Invoices', routerLink: '/reports/client-draft-invoice' },
        { reportName: 'Client Invoices', routerLink: '/reports/client-invoice' }
      ];
      this.reportOptions = [];
      this.selectedReportOption = null;
    } else {
      this.selectedReportType = this.sortedReportTypes.find(type => type.reportTypeId === reportTypeId)?.reportTypeName || null;

      // Filter and sort reports by reportOrder
      this.reportOptions = this.allReports
        .filter(report => report.reportTypeId === reportTypeId)
        .sort((a, b) => a.reportOrder - b.reportOrder);
      this.staticReportOptions = [];
      this.selectedReportOption = null;
    }
  }

  // Navigate to dynamic reports
  navigateToReport(report: PowerBIReportInformationDTO): void {
    this.router.navigate(['/reports'], {
      queryParams: {
        reportId: report.powerBIReportId,
        workspaceId: report.powerBIWorkspaceId,
      }
    });
  }

  // Navigate to static reports
  navigateToStaticReport(routerLink: string): void {
    this.router.navigate([routerLink]);
  }
}
