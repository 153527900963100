export enum Permission {
  //Global Terms
  ViewGlobalTerms = 'View Global Terms',
  AddGlobalTerms = 'Add Global Terms',
  EditGlobalTerms = 'Edit Global Terms',
  DeleteGlobalTerms = 'Delete Global Terms',

  //Team
  ViewTeam = 'View Team',
  AddTeam = 'Add Team',
  EditTeam = 'Edit Team',
  DeleteTeam = 'Delete Team',

  //Agreement Template
  ViewAgreementTemplate = 'View Agreement Template',
  AddAgreementTemplate = 'Add Agreement Template',
  EditAgreementTemplate = 'Edit Agreement Template',
  DeleteAgreementTemplate = 'Delete Agreement Template',

  //Client Module
  ViewClient = 'View Client',
  AddClient = 'Add Client',
  EditClient = 'Edit Client',
  DeleteClient = 'Delete Client',
  ViewClientUser = 'View Client User',

  //Vendor Module
  ViewVendor = 'View Vendor',
  AddVendor = 'Add Vendor',
  EditVendor = 'Edit Vendor',
  DeleteVendor = 'Delete Vendor',

  //Vendor Contract
  ViewVendorContract = 'View Vendor Contract',

  //Vendor Note Logs
  ViewVendorNoteLogs = 'View Vendor Note Logs',
  EditVendorNoteLogs = 'Edit Vendor Note Logs',
  DeleteVendorNoteLogs = 'Delete Vendor Note Logs',
  AddVendorNoteLogs = 'Add Vendor Note Logs',

  //Vendor Documents
  ViewVendorDocuments = 'View Vendor Documents',
  AddVendorDocuments = 'Add Vendor Documents',
  EditVendorDocuments = 'Edit Vendor Documents',
  DeleteVendorDocuments = 'Delete Vendor Documents',

  //Vendor Rates
  ViewVendorRates = 'View Vendor Rates',
  AddVendorRates = 'Add Vendor Rates',
  DeleteVendorRates = 'Delete Vendor Rates',
  EditVendorRates = 'Edit Vendor Rates',

  //Vendor Technicians
  ViewVendorTechnicians = 'View Vendor Technicians',
  AddVendorTechnicians = 'Add Vendor Technicians',
  EditVendorTechnicians = 'Edit Vendor Technicians',

  //Vendor Technicians Notes
  ViewVendorTechnicianNotes = 'View Vendor Technicians Notes',
  AddVendorTechnicianNotes = 'Add Vendor Technicians Notes',
  EditVendorTechnicianNotes = 'Edit Vendor Technicians Notes',
  DeleteVendorTechnicianNotes = 'Delete Vendor Technicians Notes',

  //Vendor Technicians Documents
  ViewVendorTechnicianDocuments = 'Add Vendor Technician Documents',
  AddVendorTechnicianDocuments = 'View Vendor Technician Documents',
  EditVendorTechnicianDocuments = 'Edit Vendor Technician Documents',
  DeleteVendorTechnicianDocuments = 'Delete Vendor Technician Documents',

  //Vendor Technicians Profile
  EditVendorTechnicianProfile = 'Edit Vendor Technician Profile',
  ViewVendorTechnicianProfile = 'View Vendor Technician Profile',

  //Vendor Technician Contract
  ViewVendorTechnicianContract = 'View Vendor Technician Contracts',

  //Deal
  ViewDeal = 'All Deals',
  EditDeal = 'Edit Deals',
  DeleteDeal = 'Delete Deals',
  AddDeal = 'Add Deals',

  //Contract
  ViewContract = 'View Contract',
  AddContract = 'Add Contract',
  EditContract = 'Edit Contract',
  DeleteContract = 'Delete Contract',

  //Contract Activity
  ViewContractActivity = 'View Contract Activity',
  AddContractActivity = 'Add Contract Activity',
  EditContractActivity = 'Edit Contract Activity',
  DeleteContractActivity = 'Delete Contract Activity',

  //Contract Activity Resource
  ViewContractActivityResource = 'View Contract Activity Resource',
  AddContractActivityResource = 'Add Contract Activity Resource',
  EditContractActivityResource = 'Edit Contract Activity Resource',
  DeleteContractActivityResource = 'Delete Contract Activity Resource',

  //Contract Document
  ViewContractDocument = 'View Contract Document',
  AddContractDocument = 'Add Contract Document',
  EditContractDocument = 'Edit Contract Document',
  DeleteContractDocument = 'Delete Contract Document',

  //Delivery Module
  ViewDelivery = 'View Delivery',

  //Delivery Contract
  ViewDeliveryContract = 'View Delivery Contract',
  EditDeliveryContract = 'Edit Delivery Contract',

  //Delivery Contract Ticket
  ViewDeliveryContractTicket = 'View Delivery Contract Ticket',
  ViewDeliveryContractTicketUploads = 'View Delivery Contract Ticket Uploads',
  AddDeliveryContractTicket = 'Add Delivery Contract Ticket',
  DeleteDelivertContractTicket = 'Delete Delivery Contract Ticket',

  //Delivery Contract Activity
  ViewDeliveryContractActivity = 'View Delivery Contract Activity',
  AddDeliveryContractActivity = 'Add Delivery Contract Activity',

  //Delivery Contract Document
  ViewDeliveryContractDocument = 'View Delivery Contract Document',
  AddDeliveryContractDocument = 'Add Delivery Contract Document',
  EditDeliveryContractDocument = 'Edit Delivery Contract Document',
  DeleteDeliveryContractDocument = 'Delete Delivery Contract Document',

  //Delivery Contract Incidents
  ViewDeliveryContractIncident = 'View Delivery Contract Incidents',
  AddDeliveryContractIncident = 'Add Delivery Contract Incidents',
  EditDeliveryContractIncident = 'Edit Delivery Contract Incidents',
  DeleteDeliveryContractIncident = 'Delete Delivery Contract Incidents',

  //Delivery Contract Financials
  ViewDeliveryContractFinancial = 'View Delivery Contract Financials',

  //Deilvery Contract Ticket Labels
  AddDeliveryContractTicketLabel = 'Add Delivery Contract Ticket Label',

  //Delivery Contract Ticket Activity
  ViewDeliveryContractTicketActivity = 'View Delivery Contract Ticket Activity',
  AddDeliveryContractTicketActivity = 'Add Delivery Contract Ticket Activity',
  EditDeliveryContractTicketActivity = 'Edit Delivery Contract Ticket Activity',
  DeleteDeliveryContractTicketActivity = 'Delete Delivery Contract Ticket Activity',

  //Delivery Contract Ticket Incident
  ViewDeliveryContractTicketIncident = 'View Delivery Contract Ticket Incidents',
  AddDeliveryContractTicketIncident = 'Add Delivery Contract Ticket Incidents',
  EditDeliveryContractTicketIncident = 'Edit Delivery Contract Ticket Incidents',
  DeleteDeliveryContractTicketIncident = 'Delete Delivery Contract Ticket Incidents',

  //Delivery Contract Ticket Site
  ViewDeliveryContractTicketSite = 'View Delivery Contract Ticket Site',
  EditDeliveryContractTicketSite = 'Edit Delivery Contract Ticket Site',

  //Delivery Contract Ticket Note
  ViewDeliveryContractTicketNote = 'View Delivery Contract Ticket Note',
  EditDeliveryContractTicketNote = 'Edit Delivery Contract Ticket Note',
  DeleteDeliveryContractTicketNote = 'Delete Delivery Contract Ticket Note',
  AddDeliveryContractTicketNote = 'Add Delivery Contract Ticket Note',

  //Delivery Contract Ticket Document
  ViewDeliveryContractTicketDocument = 'View Delivery Contract Ticket Document',
  EditDeliveryContractTicketDocument = 'Edit Delivery Contract Ticket Document',
  DeleteDeliveryContractTicketDocument = 'Delete Delivery Contract Ticket Document',
  AddDeliveryContractTicketDocument = 'Add Delivery Contract Ticket Document',

  //Delivery Contract Ticket Schedule
  AddDeliveryContractTicketSchedule = 'Add Delivery Contract Ticket Schedules',
  EditDeliveryContractTicketSchedule = 'Edit Delivery Contract Ticket Schedules',
  ViewDeliveryContractTicketSchedule = 'View Delivery Contract Ticket Schedules',

  //Delivery Contract Ticket Buy
  AddDeliveryContractTicketBuy = 'Add Delivery Contract Ticket Buy',
  EditDeliveryContractTicketBuy = 'Edit Delivery Contract Ticket Buy',
  DeleteDeliveryContractTicketBuy = 'Delete Delivery Contract Ticket Buy',
  ViewDeliveryContractTicketBuy = 'View Delivery Contract Ticket Buy',
  AssignTechnician = 'Assign Technician',

  ViewActivityApproveException = 'View Activity Approve Exception',
  //Revenue Sales Deal Deal-Detail Proposal Agreement Activity

  AddActivitiesToAgreement = 'Add Activity Agreement',

  //Permission
  ViewPermission = 'View Permission',
  AddPermission = 'Add Permission',
  EditPermission = 'Edit Permission',
  DeletePermission = 'Delete Permission',

  //Settiing
  AddCountry = 'Add Country',
  EditCountry = 'Edit Country',
  DeleteCountry = 'Delete Country',
  ViewCountry = 'View Country',
  AddCurrency = 'Add Currency',
  EditCurrency = 'Edit Currency',
  DeleteCurrency = 'Delete Currency',
  ViewCurrency = 'View Currency',
  AddRegion = 'Add Region',
  EditRegion = 'Edit Region',
  DeleteRegion = 'Delete Region',
  ViewRegion = 'View Region',
  AddClientIntegrationSource = 'Add Client Integration Source',
  EditClientIntegrationSource = 'Edit Client Integration Source',
  DeleteClientIntegrationSource = 'Delete Client Integration Source',
  ViewClientIntegrationSource = 'View Client Integration Source',
  AddIntegrationSource = 'Add Integration Source',
  EditIntegrationSource = 'Edit Integration Source',
  DeleteIntegrationSource = 'Delete Integration Source',
  ViewIntegrationSource = 'View Integration Source',
  ViewAuditHistory = 'View Audit History',
  ViewDocumentType = 'View Document Type',
  AddDocumentType = 'Add Document Type',
  DeleteDocumentType = 'Delete Document Type',
  EditDocumentType = 'Edit Document Type',
  ViewDeliverableType = 'View Deliverable Type',
  AddDeliverableType = 'Add Deliverable Type',
  EditDeliverableType = 'Edit Deliverable Type',
  DeleteDeliverableType = 'Delete Deliverable Type',
  ViewLabel = 'View Label',
  AddLabel = 'Add Label',
  EditLabel = 'Edit Label',
  DeleteLabel = 'Delete Label',
  ViewReasonCode = 'View Reason Code',
  AddReasonCode = 'Add Reason Code',
  EditReasonCode = 'Edit Reason Code',
  DeleteReasonCode = 'Delete Reason Code',
  ViewReasonCodeType = 'View Reason Code Type',
  AddReasonCodeType = 'Add Reason Code Type',
  EditReasonCodeType = 'Edit Reason Code Type',
  DeleteReasonCodeType = 'Delete Reason Code Type',
  ViewVendorType = 'View Vendor Type',
  AddVendorType = 'Add Vendor Type',
  EditVendorType = 'Edit Vendor Type',
  DeleteVendorType = 'Delete Vendor Type',
  ViewCustomField = 'View Custom Field',
  AddCustomField = 'Add Custom Field',
  EditCustomField = 'Edit Custom Field',
  DeleteCustomField = 'Delete Custom Field',
  ViewCustomFieldSet = 'View Custom Field Set',
  AddCustomFieldSet = 'Add Custom Field Set',
  EditCustomFieldSet = 'Edit Custom Field Set',
  DeleteCustomFieldSet = 'Delete Custom Field Set',
  ViewDeactivateClient = 'View Deactivate Client',
  ViewResourceCategory = 'View Resource Category',
  AddResourceCategory = 'Add Resource Category',
  EditResourceCategory = 'Edit Resource Category',
  DeleteResourceCategory = 'Delete Resource Category',
  AddIncidentCategory = 'Add Incident Categories',
  EditIncidentCategory = 'Edit Incident Categories',
  ViewIncidentCategory = 'View Incident Categories',
  DeleteIncidentCategory = 'Delete Incident Categories',
  AddCompetencyType = 'Add Competency Type',
  EditCompetencyType = 'Edit Competency Type',
  DeleteCompetencyType = 'Delete Competency Type',
  ViewCompetencyType = 'View Competency Type',
  AddUserRole = 'Add User Role',
  EditUserRole = 'Edit User Role',
  DeleteUserRole = 'Delete User Role',
  ViewUserRole = 'View User Role',
  AddMilestone = 'Add Milestone',
  EditMilestone = 'Edit Milestone',
  DeleteMilestone = 'Delete Milestone',
  ViewMilestone = 'View Milestone',
  AddTaskBuilder = 'Add Task Builder',
  EditTaskBuilder = 'Edit Task Builder',
  DeleteTaskBuilder = 'Delete Task Builder',
  ViewTaskBuilder = 'View Task Builder',
  AddCompetency = 'Add Competency',
  EditCompetency = 'Edit Competency',
  DeleteCompetency = 'Delete Competency',
  ViewCompetency = 'View Competency',
  AddActivityCategory = 'Add Activity Category',
  EditActivityCategory = 'Edit Activity Category',
  DeleteActivityCategory = 'Delete Activity Category',
  ViewActivityCategory = 'View Activity Category',
  AddResource = 'Add Resource',
  EditResource = 'Edit Resource',
  DeleteResource = 'Delete Resource',
  ViewResource = 'View Resource',
  AddUser = 'Add User',
  EditUser = 'Edit User',
  DeleteUser = 'Delete User',
  ViewUser = 'View User',
  AddResponseInterval = 'Add Response Interval',
  EditResponseInterval = 'Edit Response Interval',
  DeleteResponseInterval = 'Delete Response Interval',
  ViewResponseInterval = 'View Response Interval',

  // groups permissions
  ViewGeneral = 'View General',
  EditGeneral = 'Edit General',
  ViewClientModule = 'View Client Module',
  EditClientModule = 'Edit Client Module',
  ViewIntegration = 'View Integration',
  EditIntegration = 'Edit Integration',
  ViewDeliveryModule = 'View Delivery Module',
  EditDeliveryModule = 'Edit Delivery Module',
  ViewVendorModule = 'View Vendor Module',
  EditVendorModule = 'Edit Vendor Module',
  ViewManageCatalogs = 'View Manage Catalogs',
  EditManageCatalogs = 'Edit Manage Catalogs',
  AddProblemCode = 'Add Problem Code',
  EditProblemCode = 'Edit Problem Code',
  DeleteProblemCode = 'Delete Problem Code',
  ViewProblemCode = 'View Problem Code',
  ViewTaskRankingScore='View Task Ranking Score',
  EditTaskRankingScore='Edit Task Ranking Score',

  //Delivery Dashboard Module
  ViewDeliveryDashboard = 'View Delivery Dashboard',
  DeactivateActivity = 'Deactivate Activtiy',
  ViewMyTasks = 'View My Tasks',
  ViewUnassignedTasks = 'View Unassigned Tasks',

  //Reports
  ViewClientDraftInvoices = 'View Client Draft Invoices',
  ViewClientInvoices = 'View Client Invoices',

}

//   if(ele.section == 'Client')
//   else if(ele.section == 'Vendor')
//   else if(ele.section == 'Agreement Template')
//   else if(ele.section == 'Global Terms')
//   else if(ele.section == 'Team')
//   else if(ele.section == 'Deal')
//   else if(ele.section == 'Contract')
//   else if(ele.section == 'Delivery')

export enum PermmissionSection {
  Client = 'Client',
  Vendor = 'Vendor',
  AgreementTemplate = 'Agreement Template',
  GlobalTerms = 'Global Terms',
  Team = 'Team',
  Deal = 'Deal',
  Contract = 'Contract',
  Delivery = 'Delivery',
  DeliveryDashboard = 'DeliveryDashboard',
  Settings = 'Settings',
  Reports = 'Reports',
  Permission = 'Permission',
}
