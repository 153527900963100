<h3>
  Tickets
</h3>

<mat-form-field>
  <mat-label>Search...</mat-label>
  <input [(ngModel)]="input" autocomplete="off" matInput type="text">
</mat-form-field>

<d1-infinite-scroll-container (change)="cacheState($event)" [configuration]="configuration"
                              [initialValues]="initialValues ?? undefined"
                              [query]="{contractId: contractId, query: input}"></d1-infinite-scroll-container>
