import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowNavbarComponent} from './field-flow-navbar.component';
import {MatIconModule} from "@angular/material/icon";
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {UserDropdownComponent} from './components/user-dropdown/user-dropdown.component';
import {NavbarDropdownComponent} from './components/navbar-dropdown/navbar-dropdown.component';
import {SettingsDropdownComponent} from './components/settings-dropdown/settings-dropdown.component';
import {RouterModule} from "@angular/router";
import { FieldFlowPillModule } from "../field-flow-pill/field-flow-pill.module";


@NgModule({
  declarations: [
    FieldFlowNavbarComponent,
    UserProfileComponent,
    UserDropdownComponent,
    NavbarDropdownComponent,
    SettingsDropdownComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    FieldFlowPillModule
],
  exports: [
    FieldFlowNavbarComponent,
  ]
})
export class FieldFlowNavbarModule {
}
