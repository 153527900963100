<div class="navbar">

  <img alt="D1 Logo" src="/assets/d1_logo.svg">
  <fieldflow-pill *ngIf="environmentName">
    {{ environmentName }}
  </fieldflow-pill>
  <div class="wrapper">

    <button (click)="searchClicked.emit()" class="search-wrapper">

      <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
      <div class="search-text">Search anything...</div>

      <div class="keybinding-indicator">

        <div>
          CTRL
        </div>
        <div>
          K
        </div>

      </div>

    </button>

    <div class="action-wrapper">

      <button (click)="handleDropdownClick('settings')" class="action">
        <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
      </button>

      <div class="dropdown-wrapper settings-dropdown">

        <lib-settings-dropdown [options]="options" [state]="settingsDropdownAnimationState"></lib-settings-dropdown>

      </div>

      <lib-user-profile (click)="handleDropdownClick('user')" [name]="name" [role]="role"></lib-user-profile>

    </div>

  </div>

</div>

<div class="dropdown-wrapper user-dropdown">

  <lib-user-dropdown (logOut)="logOut.emit()" [state]="userDropdownAnimationState"></lib-user-dropdown>

</div>
