<h3>
  Contracts
</h3>

<mat-form-field>
  <mat-label>Search...</mat-label>
  <input [(ngModel)]="input" autocomplete="off" matInput type="text">
</mat-form-field>

<mat-slide-toggle [(ngModel)]="hideInactive">
  Hide Inactive Contracts
</mat-slide-toggle>

<d1-infinite-scroll-container (change)="cacheState($event)" [configuration]="configuration"
                              [initialValues]="initialValues ?? undefined"
                              [query]="{clientId: clientId, hideInactive: hideInactive, query: input}"></d1-infinite-scroll-container>
