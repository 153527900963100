import { NgModule } from "@angular/core";
import { TechnicianSchedulingConflictComponent } from "./technician-scheduling-conflict.component";
import { CommonModule } from "@angular/common";
import { FieldFlowBannerModule } from "projects/fieldflow-ui/src/lib/components/field-flow-banner/field-flow-banner.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FieldFlowPillModule } from "projects/fieldflow-ui/src/lib/components/field-flow-pill/field-flow-pill.module";


@NgModule({
  declarations: [
    TechnicianSchedulingConflictComponent
  ],
  exports: [
    TechnicianSchedulingConflictComponent
  ],
  imports: [
    FieldFlowBannerModule,
    MatTooltipModule,
    FieldFlowPillModule,
    CommonModule
  ]
})
export class TechnicianSchedulingConflictModule {
}
