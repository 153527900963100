import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {
  InfiniteScrollConfigurationModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-configuration.model";
import {LabeledDataModel} from "../scroll-item/models/labeled-data.model";
import {BehaviorSubject, map, Observable, Subject, take, takeUntil} from "rxjs";
import {ScrollItemComponent} from "../scroll-item/scroll-item.component";
import {Ticket} from "../../../../../../models/tickets/tickets";
import {DeliveryContractService} from "../../../../../../services/deliveryContract/delivery-contract.service";
import {TableFilters} from "../../../../../../shared/common/tableFilters";
import {
  DeliverySearchState,
  UpdateDeliverySearchTicket
} from "../../../../../../state/delivery-search/delivery-search.state";
import {
  InfiniteScrollItemModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";
import {Select, Store} from "@ngxs/store";
import {DeliverySearchStateModel} from "../../../../../../state/delivery-search/models/delivery-search.state-model";
import {DeliverySearchCategoryEnum} from "../scroll-item/enums/delivery-search-category.enum";

@Component({
  selector: 'app-ticket-search',
  templateUrl: './ticket-search.component.html',
  styleUrls: ['./ticket-search.component.css']
})
export class TicketSearchComponent implements OnInit, OnDestroy {

  @Input() contractId: number;

  @Select(DeliverySearchState) deliverySearchState$: Observable<DeliverySearchStateModel>;

  initialValues: { items: InfiniteScrollItemModel<LabeledDataModel<Ticket>>[], totalRecords: number } | null = null;

  selection$ = new BehaviorSubject<string | null>(null);

  input: string;

  componentDestroyed$ = new Subject<void>();
  configuration: InfiniteScrollConfigurationModel<LabeledDataModel<Ticket>> = {
    pageSize: 20,
    loadCallback: (pagination, query) => {
      return this.deliveryContractService.getTickets(new TableFilters({
        page: pagination.page,
        pageSize: pagination.pageSize,
        search: query.query ?? ''
      }), query.contractId).pipe(
        map((response) => {
          return {
            records: ((response.results?.data ?? []) as any[]).map((ticket) => {
              return {
                item: {
                  label: ticket.title,
                  heading: ticket.number,
                  category: DeliverySearchCategoryEnum.ticket,
                  data: ticket
                },
                guid: ticket.id.toString()
              }
            }),
            totalRecords: response.results?.total ?? 0
          }
        })
      )
    },
    itemComponent: ScrollItemComponent,
    context: {
      guidSelected$: this.selection$
    }
  }

  constructor(private deliveryContractService: DeliveryContractService, private store: Store) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    // Initialize selection with the active record guid
    this.deliverySearchState$.pipe(
      take(1),
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      if (state.tickets?.activeRecordGuid) {
        this.selection$.next(state.tickets.activeRecordGuid);
      }

      if (state.tickets?.items) {
        this.initialValues = {
          items: state.tickets.items,
          totalRecords: state.tickets.totalRecords!
        };
      }
    });

    this.deliverySearchState$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      this.input = state.tickets?.query ?? '';
    });

    this.selection$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((guid) => {
      this.store.dispatch(new UpdateDeliverySearchTicket({
        activeRecordGuid: guid
      }));
    });
  }

  cacheState(state: { items: InfiniteScrollItemModel<unknown>[], totalRecords: number }): void {
    this.store.dispatch(new UpdateDeliverySearchTicket({
      items: state.items as InfiniteScrollItemModel<LabeledDataModel<Ticket>>[],
      totalRecords: state.totalRecords,
      query: this.input
    }));
  }

}
