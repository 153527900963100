import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SidebarMenuOption} from "../field-flow-sidebar/models/sidebar-menu-option";
import {SettingsDropdownOptionModel} from "../field-flow-navbar/models/settings-dropdown-option.model";

@Component({
  selector: 'fieldflow-primary-layout',
  templateUrl: './field-flow-primary-layout.component.html',
  styleUrls: ['./field-flow-primary-layout.component.css']
})
export class FieldFlowPrimaryLayoutComponent implements OnInit {

  @Input() sidebarOptions: SidebarMenuOption[];
  @Input() name: string;
  @Input() role: string;
  @Output() searchClicked = new EventEmitter();
  @Output() logOut = new EventEmitter();
  @Input() options: SettingsDropdownOptionModel[] = [];
  @Input () environmentName:string;
  constructor() {
  }

  ngOnInit(): void {

  }

}
