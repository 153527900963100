import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  InfiniteScrollConfigurationModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-configuration.model";
import {ScrollItemComponent} from "../scroll-item/scroll-item.component";
import {ClientService} from "../../../../../../services/client/client.service";
import {Client} from "../../../../../../models/client/client";
import {BehaviorSubject, map, Observable, Subject, take, takeUntil} from "rxjs";
import {
  InfiniteScrollItemModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";
import {LabeledDataModel} from "../scroll-item/models/labeled-data.model";
import {Select, Store} from "@ngxs/store";
import {
  DeliverySearchState,
  UpdateDeliverySearchClient
} from "../../../../../../state/delivery-search/delivery-search.state";
import {DeliverySearchStateModel} from "../../../../../../state/delivery-search/models/delivery-search.state-model";
import {DeliverySearchCategoryEnum} from "../scroll-item/enums/delivery-search-category.enum";

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.css']
})
export class ClientSearchComponent implements OnInit, OnDestroy {

  input = '';

  selection$ = new BehaviorSubject<string | null>(null);

  initialValues: { items: InfiniteScrollItemModel<LabeledDataModel<Client>>[], totalRecords: number } | null = null;

  @Select(DeliverySearchState) deliverySearchState$: Observable<DeliverySearchStateModel>;

  componentDestroyed$ = new Subject<void>();

  configuration: InfiniteScrollConfigurationModel<LabeledDataModel<Client>> = {
    pageSize: 20,
    loadCallback: (pagination, query) => {
      return this.clientService.getClients({
        pageNumber: pagination.page,
        name: query,
        hasContract: true
      }).pipe(
        map((response) => {
          return {
            records: (response.results as any[]).map((client) => {
              return {
                item: {
                  label: client.name,
                  category: DeliverySearchCategoryEnum.client,
                  data: client
                },
                guid: client.id.toString()
              }
            }),
            totalRecords: response.totalRecords ?? 0
          }
        })
      )
    },
    itemComponent: ScrollItemComponent,
    context: {
      guidSelected$: this.selection$
    }
  }

  constructor(private clientService: ClientService, private store: Store) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    // Initialize selection with the active record guid
    this.deliverySearchState$.pipe(
      take(1),
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      if (state.clients?.activeRecordGuid) {
        this.selection$.next(state.clients.activeRecordGuid);
      }

      if (state.clients?.items) {
        this.initialValues = {
          items: state.clients.items,
          totalRecords: state.clients.totalRecords!
        };
      }
    });

    this.selection$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((guid) => {
      this.store.dispatch(new UpdateDeliverySearchClient({
        activeRecordGuid: guid
      }))
    });

    this.deliverySearchState$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      this.input = state.clients?.query ?? '';
    });
  }

  cacheState(state: { items: InfiniteScrollItemModel<unknown>[], totalRecords: number }): void {
    this.store.dispatch(new UpdateDeliverySearchClient({
      items: state.items as InfiniteScrollItemModel<LabeledDataModel<Client>>[],
      totalRecords: state.totalRecords,
      query: this.input
    }));
  }

}
