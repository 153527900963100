import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import {
	AssociatedEntities,
	Client,
	ClientDocument,
	ClientDocumentRequest,
	ClientRelationshipType,
} from 'src/app/models/client/client';
import { ClientSite } from 'src/app/models/client/client-site';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { environment } from 'src/environments/environment';
import { clientworkorders } from 'src/app/models/client-workorders/clientworkorders';
import { Notes, NotesRequest } from 'src/app/models/common/notes';
import { TableFilters } from 'src/app/shared/common/tableFilters';
import { string32 } from 'pdfjs-dist/types/src/shared/util';
import { PointOfContact } from './models/contact';
@Injectable({
	providedIn: 'root',
})
export class ClientService {
	currentClientId: number;

	constructor(private httpClient: HttpClient) { }

	public copyDataFromParentToChild = {
		id: 0,
	};

	/// Set HTTP URI params if value is set
	private setParamsConditionally(params: HttpParams, param: string, value: string | number | boolean | null | undefined): HttpParams {
		let newParams: HttpParams = params;
		if (value != null) {
			newParams = params.set(param, value!)
		}

		return newParams;
	}

	getAllClients(): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/Client/AllClientsList`
		);
	}

	getClients(options: GetAllClientOptions): Observable<any> {
		const params = new HttpParams();

		const paramMap = new Map<string, string | number | boolean | null | undefined>();
		paramMap.set('pageNumber', options.pageNumber);
		paramMap.set('name', options.name);
		paramMap.set('country', options.country);
		paramMap.set('city', options.city);
		paramMap.set('orderby', options.orderBy);
		paramMap.set('sort', options.sort);
		paramMap.set('hasContract', options.hasContract);

		// Since HttpParams are immutable, a new instance is created for each param added
		let result: HttpParams = params;
		for (let [paramName, paramValue] of paramMap.entries()) {
			// Set result to the new result. Params added will be compounded until the final
			// result is complete.
			result = this.setParamsConditionally(result, paramName, paramValue);
		}

		return this.httpClient.get<any>(`${environment.clientApiBaseUrl}/Client/`, {
			params: result,
		});
	}

	getClientById(clientId: number): Observable<any> {
		const params = new HttpParams().set('clientId', clientId);
		return this.httpClient.get<Client>(
			`${environment.clientApiBaseUrl}/Client/${clientId}`,
			{ params }
		);
	}
	getAllContries(): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.configurationsApiBaseUrl}/countrydropdownlist`
		);
	}
	getTimeZone(clientId: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites/time-zone`
		);
	}
	
	getTimeZoneByIana(ianaTimezone: string, clientId: number = 0): Observable<any> {
		const params = new HttpParams().set('ianaTimezone', ianaTimezone);
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites/time-zone-by-iana`, {params});
	}
	getSiteById(clientId: number, siteId: number): Observable<any> {
		return this.httpClient.get<ClientSite>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites/${siteId}`
		);
	}
	updateClientById(client: any): Observable<Client> {
		return this.httpClient.put<Client>(
			`${environment.clientApiBaseUrl}/Client/`,
			client
		);
	}
	updateClient(client: Client): Observable<Client> {
		return this.httpClient.put<Client>(
			`${environment.clientApiBaseUrl}/Client`,
			client
		);
	}
	GetWorkorderByClientId(id: number): Observable<any> {
		return this.httpClient.get<clientworkorders>(
			`${environment.clientApiBaseUrl}/Client/${id}/tickets`
		);
	}

	createDocument(
		document: ClientDocumentRequest,
		clientId: number
	): Observable<ResponsePayloadDTO<number>> {
		return this.httpClient.post<ResponsePayloadDTO<number>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/documents`,
			document
		);
	}
	updateDocument(
		document: ClientDocumentRequest,
		clientId: number
	): Observable<ResponsePayloadDTO<number>> {
		document.created = new Date()
		return this.httpClient.put<ResponsePayloadDTO<number>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/documents`,
			document
		);
	}

	getClientDocumentsByClientId(
		clientId: number
	): Observable<ResponsePayloadDTO<ClientDocument[]>> {
		return this.httpClient.get<ResponsePayloadDTO<ClientDocument[]>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/documents`
		);
	}

	getDocumentByDocumentId(
		id: number
	): Observable<ResponsePayloadDTO<ClientDocument>> {
		return this.httpClient.get<ResponsePayloadDTO<ClientDocument>>(
			`${environment.clientApiBaseUrl}/clients/documents/${id}`
		);
	}

	deleteDocumentById(id: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.clientApiBaseUrl}/clients/documents/${id}`
		);
	}

	deleteClientById(id: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.clientApiBaseUrl}/Client?clientId=${id}`
		);
	}

	deleteClientSiteById(id: number, clientId: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites/${id}`
		);
	}

	createClient(client: Client): Observable<any> {
		return this.httpClient.post<Client>(
			`${environment.clientApiBaseUrl}/Client`,
			client
		);
	}
	getClientSite(
		pageNumber: number,
		clientId: number,
		country: string,
		filter: string,
		options?: GetClientSiteOptions,
	): Observable<any> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('country', country?.toString())
			.set('filter', filter?.toString());

		if (options?.recordsPerPage != null) {
			params = params.set('recordsPerPage', options!.recordsPerPage);
		}

		return this.httpClient.get<ClientSite>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites`,
			{ params }
		);
	}
	createClientSite(clientSite: ClientSite, clientId: number): Observable<any> {
		return this.httpClient.post<ClientSite>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites`,
			clientSite
		);
	}
	updateClientSite(clientSite: ClientSite, clientId: number): Observable<ClientSite> {
		return this.httpClient.put<ClientSite>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/sites`,
			clientSite
		);
	}

	getAllClientNotes(clientId: number): Observable<ResponsePayloadDTO<Notes[]>> {
		return this.httpClient.get<ResponsePayloadDTO<Notes[]>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/notes`
		);
	}
	getClientNoteById(noteId: number): Observable<ResponsePayloadDTO<Notes>> {
		return this.httpClient.get<ResponsePayloadDTO<Notes>>(
			`${environment.clientApiBaseUrl}/clients/notes/${noteId}`
		);
	}

	createClientNote(
		clientNote: NotesRequest,
		clientId: number
	): Observable<ResponsePayloadDTO<number>> {
		return this.httpClient.post<ResponsePayloadDTO<number>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/notes`,
			clientNote
		);
	}
	updateClientNote(
		clientNote: NotesRequest,
		clientId: number
	): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.put<ResponsePayloadDTO<boolean>>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/notes`,
			clientNote
		);
	}
	deleteClientNote(noteId: number): Observable<ResponsePayloadDTO<boolean>> {
		return this.httpClient.delete<ResponsePayloadDTO<boolean>>(
			`${environment.clientApiBaseUrl}/clients/notes/${noteId}`
		);
	}

	getPagedClientNotes(payload: TableFilters, clientId: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/clients/${clientId}/notes?sort=${payload.sort}&search=${payload.search}&page=${payload.page}&pageSize=${payload.pageSize}&isAscending=${payload.isAscending}`);
	}

	//This method will be discarded after Client User Notes are fixed.

	getAllNotesWithClientName(clientId: number): Observable<Notes[]> {
		let note$ = this.getAllClientNotes(clientId).pipe(
			map((res) => res.results)
		);
		let clients$ = this.getClientById(clientId);

		return combineLatest([note$, clients$]).pipe(
			map(([note, clients]) =>
				note.map(
					(a) =>
					({
						id: a.id,
						clientId: a.clientId,
						clientName: clients?.results?.name,
						name: a.name,
						description: a.description,
						createdDate: a.createdDate,
					} as Notes)
				)
			)
		);
	}
	getAllDeactivatedClients(): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/Client/deactivated`
		);
	}
	activateClient(clientId: number): Observable<any> {
		return this.httpClient.patch<any>(
			`${environment.clientApiBaseUrl}/Client/${clientId}/activate`,
			null
		);
	}

	getAllClientList(): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/Client/AllClientsList`
		);
	}
	getAllEndClientsList(id: any): Observable<any> {
		return this.httpClient.get<any>(`${environment.clientApiBaseUrl}/Client/${id}/AllEndClientsList`);
	}

	getRelationshipTypes(): Observable<
		ResponsePayloadDTO<ClientRelationshipType[]>
	> {
		return this.httpClient.get<ResponsePayloadDTO<ClientRelationshipType[]>>(
			`${environment.clientApiBaseUrl}/client/relationship-types`
		);
	}

	getDocumentDownloadUrl(id: number): string {
		return `${environment.utilityApiBaseUrl}/document/client/${id}`;
	}
	checkClientHQ(clientId: number, siteId: number): Observable<any> {
		// GetClientSiteDetails
		// const params = new HttpParams()
		//   .set('clientId', clientId)
		//   .set('siteId', siteId);
		return this.httpClient.get<any>(
			`${environment.clientApiBaseUrl}/Client/${clientId}/${siteId}/sites`
		);
	}

	createClientContact(contact: PointOfContact): Observable<any> {
		return this.httpClient.post<any>(
			`${environment.clientApiBaseUrl}/Client/Contacts/createClientContact`,
			contact
		);
	}

	getClientContacts(clientId?: number): Observable<any> {
		if (clientId) {
			return this.httpClient.get(`${environment.clientApiBaseUrl}/Client/Contacts/${clientId}`)
		}
		return this.httpClient.get(`${environment.clientApiBaseUrl}/Client/Contacts`)
	}
	UploadLocation(data: FormData, clientId: number) {
		return this.httpClient.post(`${environment.clientApiBaseUrl}/clients/${clientId}/sites/uploadClientSite`, data);
	}

	downloadAddLocationFormTemplateURL(clientId: number): Observable<Blob> {
		return this.httpClient.get(`${environment.clientApiBaseUrl}/clients/${clientId}/sites/downloadaddlocationformtemplate`, { responseType: 'blob' });
	}

	downloadClientSiteInfoURL(clientId: number): Observable<Blob> {
		return this.httpClient.get(`${environment.clientApiBaseUrl}/clients/${clientId}/sites/downloadClientSiteInfo`, { responseType: 'blob' });
	}

	checkNameSimilarity(clientName: string): Observable<any> {
		const encodedClientName = encodeURIComponent(clientName);
		return this.httpClient.get<Client>(
			`${environment.clientApiBaseUrl}/client/nameSimilarity/${encodedClientName}`
		);
	}
}

export interface GetAllClientOptions {
	pageNumber: number,
	name?: string | null,
	country?: string | null,
	city?: string | null,
	orderBy?: string | null,
	sort?: string | null,
	hasContract?: boolean | null
}

export interface GetClientSiteOptions {
	recordsPerPage?: number | null,
}
