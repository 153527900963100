<ng-container *ngIf="state === LoadingState.loaded; else loading">

  <!-- Report Types Section -->
  <div class="secondary-sidebar">
    <label class="sidebar-title">Report Types:</label>
    <ul class="sidebar-list">
      <!-- Use app-sidebar-option for report types -->
      <li *ngFor="let reportType of sortedReportTypes">
        <app-sidebar-option (click)="onReportTypeSelect(reportType.reportTypeId)">
          {{ reportType.reportTypeName }}
        </app-sidebar-option>
      </li>
    </ul>
  </div>

  <!-- Report Details Section for Static and Dynamic Reports -->
  <div class="secondary-sidebar report-details" *ngIf="selectedReportTypeId">
    <ul class="sidebar-list">
      <!-- Static Report Options for Invoices -->
      <ng-container *ngIf="selectedReportType === 'Invoices'; else dynamicReports">
        <li *ngFor="let staticReport of staticReportOptions">
          <app-sidebar-option (click)="navigateToStaticReport(staticReport.routerLink)">
            {{ staticReport.reportName }}
          </app-sidebar-option>
        </li>
      </ng-container>

      <!-- Dynamic Report Options -->
      <ng-template #dynamicReports>
        <li *ngFor="let reportOption of reportOptions">
          <app-sidebar-option (click)="navigateToReport(reportOption)">
            {{ reportOption.reportName }}
          </app-sidebar-option>
        </li>
      </ng-template>
    </ul>
  </div>

</ng-container>

<ng-template #loading>
  <mat-progress-spinner [diameter]="35"></mat-progress-spinner>
</ng-template>