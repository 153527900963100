export const environment = {
  production: true,
  environmentName: 'uat',  
  ldClientId: "6320ec270375de1195de0f54",
  graphClietId: "aa7918d9-9360-4b3c-a074-b76d04627784",
  graphAuthority: "https://login.microsoftonline.com/07ad63b9-416f-4a20-8172-d8f7da76d69d",
  graphPermissions: ['user.read'],
  graphRedirect: "https://d1coreportal-3-uat.azurewebsites.net/",
  saleApiBaseUrl: "https://dispatchone-3.azure-api.net/uat-salesapi/v1",
  clientApiBaseUrl: "https://client-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  utilityApiBaseUrl: "https://dispatchone-3.azure-api.net/uat-utilitiesapi/v1/api",
  identity2BaseUrl: "",
  hubspotDealBaseUrl: "https://app.hubspot.com/contacts/3298274/deal/",
  identityBaseUrl: "https://identity-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  projectApiBaseUrl: "https://dispatchone-3.azure-api.net/uat-projectapi3/v1/api",
  technicianApiBaseUrl: "https://technician-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  workOrderApiBaseUrl: "https://workorder-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  vendorApiBaseUrl: "https://vendor-api-3-uat.azurewebsites.net/api",
  copilotApiBaseUrl: "https://dispatch1-copilot-api.azurewebsites.net",
  milestoneApiBaseUrl: "https://milestone-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  opportunityApiBaseUrl: "https://opportunity-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  configurationsApiBaseUrl: "https://dispatchone-3.azure-api.net/uat-configurations/v1/api",
  dispatch1IntegrationApiBaseUrl: "https://dispatchone-3.azure-api.net/dispatch1integrationapi-internal/v1/api",
  prodDataCleanApiBaseUrl: "https://dispatchone-3.azure-api.net/uat-proddatacleanapi/v1/api",
  financialsApiBaseUrl: "https://financials-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  financeApiBaseUrl: "https://finance-api.jollyrock-f258d988.eastus.azurecontainerapps.io/api",
  workmarketBaseUrl: 'https://www.workmarket.com',
  signUpSignInB2cPolicy: "B2C_1_Dispatch3_SignUpSignIn",
  editProfileB2cPolicy: "B2C_1_Dispatch3Edit",
  authorityDomain: "dispatchone.b2clogin.com",
  b2cClientId: 'd131b53f-49c8-4b77-969f-64814a717261',
  b2cAuthority: "https://dispatchone.b2clogin.com/dispatchone.onmicrosoft.com/",
  fallbackB2cRedirectUri: 'https://d1coreportal-3-uat.azurewebsites.net/',
  // If you need to add additional redirect URIs, they can be added here
  b2cRedirectUris: ['https://core.dispatch1.com/', 'https://yellow-desert-0b999b61e-preview.westus2.4.azurestaticapps.net/'],
  b2clientRequestId: "14f438b2-1dad-436f-b333-aa70babcffc6",
  b2cScopes: ["https://dispatchone.onmicrosoft.com/identity-api/identity.write", "https://dispatchone.onmicrosoft.com/identity-api/identity.read"],
  apimSubscriptionKey: "d8244b7f95824a29915fea7898f82917",
  // PowerBI Report ID's
};
