import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationBuilderComponent } from './pagination-builder.component';
import { CheckboxModule, IconModule, LoadingModule, PaginationModule, TableModule } from 'carbon-components-angular';
import { FormsModule } from '@angular/forms';
import { FilterBuilderModule } from '../filter-builder/filter-builder.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [
    PaginationBuilderComponent,
  ],
  imports: [
    CommonModule,
    TableModule,
    PaginationModule,
    IconModule,
    FormsModule,
    FilterBuilderModule,
    CheckboxModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    PaginationBuilderComponent,
  ]
})
export class PaginationBuilderModule { }
