import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from "@ngxs/store";
import {
  DeliverySearchState,
  PumpDeliverySearchSelection
} from "../../../../state/delivery-search/delivery-search.state";
import {catchError, forkJoin, map, Observable, of, Subject, switchMap, takeUntil, throwError} from "rxjs";
import {DeliverySearchStateModel} from "../../../../state/delivery-search/models/delivery-search.state-model";
import {ActivatedRoute} from "@angular/router";
import {DeliverySearchCategoryEnum} from "./components/scroll-item/enums/delivery-search-category.enum";
import {ClientService} from "../../../../services/client/client.service";
import {ContractService} from "../../../../services/contract/contract.service";
import {TicketService} from "../../../../services/ticket/ticket.service";
import {
  InfiniteScrollItemModel
} from "../../../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";
import {LabeledDataModel} from "./components/scroll-item/models/labeled-data.model";
import {LoadingState} from "../../../../state/loading-state.enum";
import {UtilityService} from "../../../../services/utility/utility.service";

@Component({
  selector: 'app-delivery-search',
  templateUrl: './delivery-search.component.html',
  styleUrls: ['./delivery-search.component.css']
})
export class DeliverySearchComponent implements OnInit, OnDestroy {

  @Select(DeliverySearchState) deliverySearchState$: Observable<DeliverySearchStateModel>;

  loadingState: LoadingState = LoadingState.loading;
  readonly LoadingState = LoadingState;

  componentDestroyed$ = new Subject<void>();

  constructor(private store: Store, private activatedRoute: ActivatedRoute, private clientService: ClientService, private contractService: ContractService, private ticketService: TicketService, private utilityService: UtilityService) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    const stateSnapshot: DeliverySearchStateModel = this.store.selectSnapshot(DeliverySearchState);

    // Determine what ids are present in the route.
    const initialCategoryIds = this.getItemIdsFromRouteForCategories();
    // For those object ids,
    // get the corresponding objects and any related objects that do not exist in the route's state.
    this.fetchObjectsForCategories(initialCategoryIds, stateSnapshot).pipe(
      takeUntil(this.componentDestroyed$),
      catchError(err => {
        this.loadingState = LoadingState.error;
        return throwError(() => err);
      })
    ).subscribe(objects => {
      this.store.dispatch(new PumpDeliverySearchSelection(objects));
      this.loadingState = LoadingState.loaded;
    });
  }

  /**
   * This method will extract the item ids from the activated route for the different categories.
   * @private
   */
  private getItemIdsFromRouteForCategories(): Map<DeliverySearchCategoryEnum, number> {
    const buffer = new Map<DeliverySearchCategoryEnum, number>();

    const matchParams = new Map<string, DeliverySearchCategoryEnum>([
      ['contractId', DeliverySearchCategoryEnum.contract],
      ['ticketId', DeliverySearchCategoryEnum.ticket],
      ['clientId', DeliverySearchCategoryEnum.client]
    ]);

    // These tokens should be identified in the current router tree.
    // If present, the corresponding activated route should be added to a buffer.
    const matchTokens = [
      'delivery',
      'ticket'
    ];

    // The current router tree should be traversed to find the activated routes that match the tokens.
    const matchedRoutes = this.utilityService.getMatchingRoutes(this.activatedRoute.root, 'sidebarToken', matchTokens);
    matchedRoutes.forEach((value, _) => {

      const params = this.getAllParamsFromRoute(value);
      params.forEach((value, key) => {
        if (matchParams.has(key)) {
          buffer.set(matchParams.get(key)!, parseInt(value));
        }
      });
    });

    return buffer;
  }

  private getAllParamsFromRoute(activatedRoute: ActivatedRoute): Map<string, string> {
    const buffer = new Map<string, string>();

    Object.keys(activatedRoute.snapshot.params).forEach(key => {
      buffer.set(key, activatedRoute.snapshot.params[key]);
    });

    if (activatedRoute.children.length > 0) {
      activatedRoute.children.forEach(child => {
        const childBuffer = this.getAllParamsFromRoute(child);
        childBuffer.forEach((value, key) => {
          buffer.set(key, value);
        });
      });
    }

    return buffer;
  }

  private getExistingStateItemForCategory(category: DeliverySearchCategoryEnum, state: DeliverySearchStateModel, guid: string): InfiniteScrollItemModel<LabeledDataModel<any>> | null {
    const stateKey = DeliverySearchState.StateKeyCategories.get(category)!;
    if (!state[stateKey]) {
      return null;
    }

    const items: InfiniteScrollItemModel<LabeledDataModel<any>>[] | undefined = state[stateKey].items;
    return items?.find(i => i.guid === guid) ?? null;
  }

  private generateClientRequest(clientId: number): Observable<InfiniteScrollItemModel<LabeledDataModel<any>>> {
    return this.clientService.getClientById(clientId).pipe(
      map(client => {
        return {
          item: {
            label: client.results.name,
            category: DeliverySearchCategoryEnum.client,
            data: client.results,
          },
          guid: client.results.id.toString(),
        }
      })
    )
  }

  private fetchObjectsForCategories(initialCategoryIds: Map<DeliverySearchCategoryEnum, number>, state: DeliverySearchStateModel): Observable<Map<DeliverySearchCategoryEnum, InfiniteScrollItemModel<LabeledDataModel<any>>>> {
    const loadObjectActions$ = new Map<DeliverySearchCategoryEnum, Observable<InfiniteScrollItemModel<LabeledDataModel<any>>>>();

    initialCategoryIds.forEach((value, key) => {
      switch (key) {
        case DeliverySearchCategoryEnum.client:
          const existingClient = this.getExistingStateItemForCategory(key, state, value.toString());
          loadObjectActions$.set(key, existingClient ? of(existingClient) : this.generateClientRequest(value));
          break;
        case DeliverySearchCategoryEnum.contract:
          const existingContract = this.getExistingStateItemForCategory(key, state, value.toString());

          loadObjectActions$.set(key, existingContract ? of(existingContract) : this.contractService.getContractById(value).pipe(
            map(contract => {
              return {
                item: {
                  label: contract.results.title!,
                  category: DeliverySearchCategoryEnum.contract,
                  data: contract.results,
                },
                guid: contract.results.id.toString(),
              }
            })
          ));
          break;
        case DeliverySearchCategoryEnum.ticket:
          const existingTicket = this.getExistingStateItemForCategory(key, state, value.toString());
          loadObjectActions$.set(key, existingTicket ? of(existingTicket) : this.ticketService.getTicketById(value).pipe(
            map(ticket => {
              return {
                item: {
                  label: ticket.results.title,
                  category: DeliverySearchCategoryEnum.ticket,
                  data: ticket.results,
                },
                guid: ticket.results.id.toString(),
              }
            })
          ));
          break;
      }
    });

    // Fix-up load observables because ticket/contract routes doesn't include a param for client.
    if (loadObjectActions$.has(DeliverySearchCategoryEnum.contract) && !loadObjectActions$.has(DeliverySearchCategoryEnum.client)) {
      loadObjectActions$.set(DeliverySearchCategoryEnum.client, loadObjectActions$.get(DeliverySearchCategoryEnum.contract)!.pipe(
        switchMap(contract => this.generateClientRequest(contract.item.data.clientId))
      ));
    }

    // Assert state
    if (loadObjectActions$.has(DeliverySearchCategoryEnum.ticket) && !loadObjectActions$.has(DeliverySearchCategoryEnum.contract)) {
      throw new Error('A ticket was provided without a contract.');
    }

    const requestArray = Array.from(loadObjectActions$.values());
    const buffer = new Map<DeliverySearchCategoryEnum, InfiniteScrollItemModel<LabeledDataModel<any>>>();
    return requestArray.length === 0 ? of(buffer) : forkJoin(requestArray).pipe(
      map(results => {
        results.forEach((result, index) => {
          buffer.set(Array.from(loadObjectActions$.keys())[index], result);
        });
        return buffer;
      })
    );
  }

}
