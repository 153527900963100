import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService,} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication,} from '@azure/msal-browser';
import {CoreModule} from './core/core.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {ProfileComponent} from './views/profile/profile.component';
import * as Sentry from '@sentry/angular-ivy';
import {ToastrModule} from 'ngx-toastr';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {IconModule, ModalModule} from 'carbon-components-angular';
import {SharedModule} from './shared/shared.module';
import {LoaderService} from './services/loader/loader.service';
import {UnauthorizedComponent} from './views/unauthorized/unauthorized.component';
import {ReactiveFormsModule} from '@angular/forms';
import {getMsalConfig} from './core/msal/msal.config';
import {NgxsModule} from '@ngxs/store';
import {FeatureFlagsService} from "./services/feature-flags/feature-flags.service";
import {FeatureFlagModule} from "./shared/directives/feature-flag/feature-flag.module";
import {StatusState} from "./state/status/status.state";
import {TechnicianState} from "./state/technicians/technician.state";
import {VendorsState} from "./state/vendors/vendors.state";
import {UserState} from "./state/users/user.state";
import {GeneralState} from "./state/general/general.state";
import {MatTooltipModule} from '@angular/material/tooltip';
import {TeamState} from "./state/team/team.state";
import {LoadingHandlerModule} from "./shared/components/loading-handler/loading-handler.module";
import {LoginComponent} from "./views/login/login.component";
import {QuillModule} from 'ngx-quill';
import {Router} from "@angular/router";
import {DeliverySearchState} from "./state/delivery-search/delivery-search.state";
import {NotFoundModule} from "./views/not-found/not-found.module";

@NgModule({
  declarations: [AppComponent, ProfileComponent, UnauthorizedComponent, LoginComponent],
  imports: [
    BrowserModule,
    ModalModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IconModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MsalModule.forRoot(
      new PublicClientApplication(getMsalConfig()),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: environment.b2cScopes,
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [environment.identityBaseUrl, environment.b2cScopes],
          [environment.milestoneApiBaseUrl, environment.b2cScopes],
          [environment.copilotApiBaseUrl, environment.b2cScopes],
          [environment.technicianApiBaseUrl, environment.b2cScopes],
          [environment.opportunityApiBaseUrl, environment.b2cScopes],
          [environment.financialsApiBaseUrl, environment.b2cScopes],
          [environment.financeApiBaseUrl, environment.b2cScopes]
        ]),
      }
    ),
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
    FeatureFlagModule,
    NgxsModule.forRoot([
      StatusState,
      TechnicianState,
      VendorsState,
      UserState,
      GeneralState,
      TeamState,
      DeliverySearchState
    ]),
    MatTooltipModule,
    NotFoundModule,
    LoadingHandlerModule,
    QuillModule.forRoot(
      {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'], // toggled buttons
            ['blockquote'],
            [{'header': 1}, {'header': 2}], // custom button values
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'indent': '-1'}, {'indent': '+1'}], // outdent/indent
            [{'size': ['small', false, 'large', 'huge']}], // custom dropdown
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'color': []}, {'background': []}], // dropdown with defaults from theme
            [{'font': []}],
            [{'align': []}],
            ['clean'] // remove formatting button
          ]
        }
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    LoaderService,
    MsalService,
    MsalGuard,
    CurrencyPipe,
    DatePipe,
    FeatureFlagsService,
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'USD'},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
}
