import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {RecordsWithCount} from "../../models/records-with-count.model";
import {ListContractModel} from "./models/list-contract.model";

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private http: HttpClient) {
  }

  paginateListContractsForClient(clientId: number, includeComplete: boolean, page: number, pageSize: number, query?: string): Observable<RecordsWithCount<ListContractModel>> {
    const httpParams = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize.toString())
      .set('includeComplete', includeComplete.toString())
      .set('search', query || '');

    return this.http.get<RecordsWithCount<ListContractModel>>(`${environment.workOrderApiBaseUrl}/contract/client/${clientId}`, {
      params: httpParams
    });
  }

}
