import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FieldFlowPillComponent} from './field-flow-pill.component';
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    FieldFlowPillComponent
  ],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    FieldFlowPillComponent
  ]
})
export class FieldFlowPillModule {
}
