import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fieldflow-pill',
  templateUrl: './field-flow-pill.component.html',
  styleUrls: ['./field-flow-pill.component.css']
})
export class FieldFlowPillComponent implements OnInit {
  @Input() icon?: string;
  @Input() color: string = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}
