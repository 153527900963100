<lib-navbar-dropdown [state]="state" title="System Settings">

  <button *ngFor="let option of options" [routerLink]="option.route" class="action">

    <mat-icon fontSet="material-symbols-outlined">{{ option.icon }}</mat-icon>
    <span>{{ option.label }}</span>

  </button>

</lib-navbar-dropdown>
