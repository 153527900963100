import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, map, Observable, Subject, take, takeUntil} from "rxjs";
import {
  InfiniteScrollConfigurationModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-configuration.model";
import {LabeledDataModel} from "../scroll-item/models/labeled-data.model";
import {ScrollItemComponent} from "../scroll-item/scroll-item.component";
import {
  DeliverySearchState,
  UpdateDeliverySearchContract
} from "../../../../../../state/delivery-search/delivery-search.state";
import {Select, Store} from "@ngxs/store";
import {
  InfiniteScrollItemModel
} from "../../../../../../shared/components/infinite-scroll-container/models/infinite-scroll-item.model";
import {DeliverySearchStateModel} from "../../../../../../state/delivery-search/models/delivery-search.state-model";
import {DeliverySearchCategoryEnum} from "../scroll-item/enums/delivery-search-category.enum";
import {ListContractModel} from "../../../../../../api-services/workorders-api/models/list-contract.model";
import {ContractService} from "../../../../../../api-services/workorders-api/contract.service";

@Component({
  selector: 'app-contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.css']
})
export class ContractSearchComponent implements OnInit, OnDestroy {

  selection$ = new BehaviorSubject<string | null>(null);

  @Input() clientId: number;

  initialValues: {
    items: InfiniteScrollItemModel<LabeledDataModel<ListContractModel>>[],
    totalRecords: number
  } | null = null;

  @Select(DeliverySearchState) deliverySearchState$: Observable<DeliverySearchStateModel>;

  componentDestroyed$ = new Subject<void>();

  input: string;

  hideInactive = false;

  configuration: InfiniteScrollConfigurationModel<LabeledDataModel<ListContractModel>> = {
    pageSize: 20,
    loadCallback: (pagination, query) => {
      return this.contractService.paginateListContractsForClient(query.clientId, !query.hideInactive, pagination.page, pagination.pageSize, query.query).pipe(
        map((response) => ({
          records: (response.records).map((contract) => {
            return {
              item: {
                label: contract.title,
                heading: contract.code,
                category: DeliverySearchCategoryEnum.contract,
                data: contract
              },
              guid: contract.id.toString()
            }
          }),
          totalRecords: response.totalRecords
        }))
      )
    },
    itemComponent: ScrollItemComponent,
    context: {
      guidSelected$: this.selection$
    }
  }

  constructor(private store: Store, private contractService: ContractService) {
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {
    // Initialize selection with the active record guid
    this.deliverySearchState$.pipe(
      take(1),
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      if (state.contracts?.activeRecordGuid) {
        this.selection$.next(state.contracts.activeRecordGuid);
      }

      // Since the client ID is not part of the route state, on a state pump,
      // we need
      // to check if the user was exploring contracts from another client
      // and that client's contracts are still in the state.
      // If they are,
      // disregard the initial state and reload based on the current router state by omitting initial items.
      const containsClientMismatch = state.contracts?.items?.some((item) => item.item.data.clientId !== this.clientId);
      if (state.contracts?.items && !containsClientMismatch) {
        this.initialValues = {
          items: state.contracts.items,
          totalRecords: state.contracts.totalRecords!
        };
      }
    });

    this.deliverySearchState$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((state) => {
      this.input = state.contracts?.query ?? '';
      this.hideInactive = state.contracts?.extras?.hideInactive ?? false;
    });

    this.selection$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((guid) => {
      this.store.dispatch(new UpdateDeliverySearchContract({
        activeRecordGuid: guid
      }));
    });
  }

  cacheState(state: { items: InfiniteScrollItemModel<unknown>[], totalRecords: number }): void {
    this.store.dispatch(new UpdateDeliverySearchContract({
      items: state.items as InfiniteScrollItemModel<LabeledDataModel<ListContractModel>>[],
      totalRecords: state.totalRecords,
      query: this.input,
      extras: {
        hideInactive: this.hideInactive
      }
    }));
  }

}
