import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {SidebarMenuOption} from "./models/sidebar-menu-option";
import {fromEvent, Observable, Subject, Subscription, switchMap} from "rxjs";

@Component({
  selector: 'fieldflow-sidebar',
  templateUrl: './field-flow-sidebar.component.html',
  styleUrls: ['./field-flow-sidebar.component.css']
})
export class FieldFlowSidebarComponent implements OnInit, AfterViewInit {

  @Input() sidebarOptions: SidebarMenuOption[];

  @ViewChild('sidebarContainer', {read: ViewContainerRef}) sidebar: ViewContainerRef;

  createdSidebarComponents: { option: SidebarMenuOption, component: ComponentRef<any> }[] = [];

  @ViewChildren('option') options: QueryList<any>;

  optionHoverChanged$ = new Subject<SidebarMenuOption | null>();

  private hoverSubscriptions: Subscription[] = [];
  private activeOption: SidebarMenuOption | null = null;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.registerHoverListeners();
    this.sidebarOptions.forEach(option => {
      if (option.subMenu) {
        this.createComponent(option, option.subMenu);
      }
    });

    this.createdSidebarComponents.forEach(c => {
      c.component.changeDetectorRef.detectChanges();
    });
  }

  ngOnInit(): void {
    this.optionHoverChanged$.subscribe((option) => {
      this.handleSubMenu(option);
    });
  }

  optionClicked(option: SidebarMenuOption) {
    if (option.href) {
      window.open(option.href, '_blank');
    }
  }

  private createComponent(option: SidebarMenuOption, subMenuComponent: Type<any>) {
    const componentRef = this.sidebar.createComponent(subMenuComponent);
    this.createdSidebarComponents.push({option, component: componentRef});
  }

  private registerHoverListeners(): void {
    // Clear any existing subscriptions
    this.hoverSubscriptions.forEach(sub => sub.unsubscribe());
    this.hoverSubscriptions = [];

    // Ensure options are available
    if (!this.options) {
      return;
    }

    this.options.forEach((optionRef: ElementRef, index: number) => {
      const element = optionRef.nativeElement;
      const option = this.sidebarOptions[index];

      // Create Observables for mouseenter and mouseleave events
      const mouseEnter$ = fromEvent(element, 'mouseenter');
      const mouseLeave$ = fromEvent(element, 'mouseleave');

      // Subscription for handling hover logic
      const hoverSubscription = mouseEnter$
        .pipe(
          switchMap(() => {
            // Check if there's an active submenu that's different from the hovered option
            if (this.activeOption && this.activeOption !== option) {
              // Delay activation
              return new Observable(observer => {
                const timer = setTimeout(() => {
                  observer.next();
                  observer.complete();
                }, 100);

                // If mouse leaves before n ms, cancel the timer
                const mouseLeaveSub = mouseLeave$.subscribe(() => {
                  clearTimeout(timer);
                  observer.complete();
                });

                // Cleanup
                return () => {
                  clearTimeout(timer);
                  mouseLeaveSub.unsubscribe();
                };
              });
            } else {
              // No active submenu or same option, activate immediately
              return new Observable(observer => {
                observer.next();
                observer.complete();
              });
            }
          })
        )
        .subscribe(() => {
          // Update the active option and handle submenu
          this.activeOption = option;
          this.handleSubMenu(option);
        });

      // Store the subscription for cleanup
      this.hoverSubscriptions.push(hoverSubscription);
    });
  }

  private handleSubMenu(option: SidebarMenuOption | null): void {
    const component = this.createdSidebarComponents.find(c => c.option === option);

    this.createdSidebarComponents.forEach(c => {
      if (c.option !== option) {
        c.component.location.nativeElement.style.display = 'none';
      }
    });

    if (component) {
      // Change display to block
      component.component.location.nativeElement.style.display = 'flex';
    }
  }

}
